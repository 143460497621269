<template>
  <b-row v-if="task != null">
    <b-col cols="9">
      <b-card>
        <h4>{{ $t("comments") }}</h4>
        <hr />
        <app-timeline>
          <app-timeline-item v-for="comment in task.comments">
            <div
              class="
                d-flex
                flex-sm-row flex-column flex-wrap
                justify-content-between
                mb-1 mb-sm-0
              "
            >
              <h6>{{ comment.user.name + " " + comment.user.last_name }}</h6>
              <small class="text-muted">{{ comment.created_at }}</small>
            </div>
            <p>{{ comment.comment }}</p>
          </app-timeline-item>
        </app-timeline>

        <label for="comment">{{ $t("comment") }}</label>
        <b-form-textarea
          id="comment"
          :placeholder="$t('comment')"
          rows="3"
          v-model="commentText"
        />

        <div class="text-right pt-1">
          <b-button variant="primary" @click="addComment()">{{
            $t("comment")
          }}</b-button>
        </div>
      </b-card>
    </b-col>
    <b-col cols="3">
      <b-card>
        <h4>{{ $t("attachments") }}</h4>
        <hr />
        <app-timeline>
          <app-timeline-item
            v-for="attachment in task.attachments"
            variant="warning"
          >
            <div
              class="
                d-flex
                flex-sm-row flex-column flex-wrap
                justify-content-between
                mb-1 mb-sm-0
              "
            >
              <h6>
                {{ attachment.user.name + " " + attachment.user.last_name }}
              </h6>
              <small class="text-muted">{{ attachment.created_at }}</small>
            </div>
            <p @click="downloadPdf(attachment.id)" class="pointer">
              <b-img
                :src="require('@/assets/images/icons/pdf.png')"
                height="auto"
                width="20"
                class="mr-1"
              />
              <b-link class="align-bottom">{{ attachment.file }} </b-link>
            </p>
          </app-timeline-item>
          <b-form-file
            v-model="file"
            @change="uploadFile($event)"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          ></b-form-file>
        </app-timeline>
      </b-card>
      <b-card>
        <h4>{{ $t("approvals") }}</h4>
        <hr />
        <app-timeline>
          <app-timeline-item
            v-for="approval in task.approves"
            :variant="approval.status == 1 ? 'success' : 'danger'"
          >
            <div
              class="
                d-flex
                flex-sm-row flex-column flex-wrap
                justify-content-between
                mb-1 mb-sm-0
              "
            >
              <h6>{{ approval.user.name + " " + approval.user.last_name }}</h6>
              <small class="text-muted">{{ approval.created_at }}</small>
            </div>
            <p>
              {{ $t("changed_status_to") }}
              <span class="text-success" v-if="approval.status == 1">{{
                $t("approved")
              }}</span>
              <span class="text-danger" v-if="approval.status == 2">{{
                $t("declined")
              }}</span>
            </p>
          </app-timeline-item>
        </app-timeline>
      </b-card>
    </b-col>
    <b-col cols="12" class="pt-3"> </b-col>
  </b-row>
</template>

<script>
import {
  BImg,
  BAvatar,
  BMedia,
  BButton,
  BCollapse,
  VBToggle,
  BListGroup,
  BListGroupItem,
  BAvatarGroup,
  BBadge,
  VBTooltip,
  BRow,
  BCol,
  BFormTextarea,
  BFormFile,
  BCard,
} from "bootstrap-vue";
import fileDownload from "js-file-download";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import Ripple from "vue-ripple-directive";

export default {
  name: "Task",
  components: {
    AppTimeline,
    AppTimelineItem,
    BImg,
    BRow,
    BCol,
    BMedia,
    BAvatar,
    BButton,
    BCollapse,
    BListGroup,
    BListGroupItem,
    BAvatarGroup,
    BBadge,
    BFormTextarea,
    BFormFile,
    BCard,
  },
  directives: { "b-toggle": VBToggle, "b-tooltip": VBTooltip, Ripple },
  props: ["task_id"],
  data() {
    return {
      isLoading: false,
      commentText: null,
      task: null,
      file: [],
    };
  },
  created() {
    this.getTask();
  },
  methods: {
    downloadPdf(id) {
      this.isLoading = true;
      this.$http
        .post(
          "/task/admin/download/" + id,
          {},
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          fileDownload(response.data, "doc.pdf");
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    uploadFile(event) {
      let formData = new FormData();
      formData.append("pdf", event.target.files[0]);
      let config = { headers: { "Content-Type": "multipart/form-data" } };
      this.$http
        .post("/task/admin/addAttachment/" + this.task.id, formData, config)
        .then((response) => {
          this.task = response.data.value;
        })
        .catch((error) => {});
    },
    getTask() {
      this.$http
        .post("/task/admin/show/" + this.task_id, { comment: this.commentText })
        .then((response) => {
          this.task = response.data.value;
        })
        .catch((error) => {});
    },
    addComment() {
      this.$http
        .post("/task/admin/addComment/" + this.task.id, {
          comment: this.commentText,
        })
        .then((response) => {
          this.task = response.data.value;
          this.commentText = null;
        })
        .catch((error) => {});
    },
  },
};
</script>

<style scoped>
</style>
