<template>
  <b-card class="">
    <div>
      <h3 class="font-weight-bolder">
        <b-link class="btn btn-outline-primary" @click="$emit('showTable')">
          <FeatherIcon icon="ChevronLeftIcon"></FeatherIcon>
          {{ $t("back") }}
        </b-link>
        {{ $t("deposit_funds") }}
      </h3>
      <br />

      {{ $t("deposit_subtitle") }} <br />
      <br />
      {{ $t("introductions") }}:
      <b-row class="col-md-12">
        <b-col md="3" sm="6" class="mb-2 mb-md-0 col-md-12 mt-3 mb-3">
          <b-media no-body>
            <b-media-body class="my-auto row">
              <h6 class="font-weight-bolder mb-0 mr-3">
                <b-avatar size="38" variant="light-primary" class="mr-1">
                  <feather-icon size="14" icon="DollarSignIcon" />
                </b-avatar>
                {{ $t("amount") }}
              </h6>
              <b-card-text class="font-small-3">
                <b-form-input type="number" v-model="amount"></b-form-input>
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col md="3" sm="6" class="mb-2 mb-md-0 col-md-12 mt-3 mb-3">
          <b-media no-body>
            <b-media-body class="my-auto row">
              <h6 class="font-weight-bolder mb-0 mr-3">
                <b-avatar size="38" variant="light-primary" class="mr-1">
                  <feather-icon size="14" icon="DollarSignIcon" />
                </b-avatar>
                {{ $t("reference_number_payment_reason") }}
              </h6>
              <b-card-text class="font-small-3">
                <b-form-input v-model="reference_number"></b-form-input>
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
      <br /><br /><br />
      <b-col class="alert alert-primary p-2 col-md-12">
        <div class="row ml-1">
          <feather-icon variant="primary" size="20" icon="InfoIcon" />
          <h5 class="text-primary ml-1">
            {{ $t("deposit_important_note") }}
          </h5>
        </div>
      </b-col>
      <br />
      <b-col
        class="justify-content-end align-items-end col-md-12 pr-0"
        align="end"
      >
        <b-button variant="primary" @click="save">{{
          $t("save_deposit")
        }}</b-button>
      </b-col>
    </div>
  </b-card>
</template>
<script>
export default {
  data() {
    return {
      amount: "",
      iban: "",
      bank_name: "",
      bank_address: "",
      reference_number: "",
      transactions: [],
      invoiceData: [
        {
          text: "IBAN",
          value: " CH23 123 5678 9101 1B",
          icon: "CreditCardIcon",
          variant: "primary",
        },
        {
          text: "bank_name",
          value: "UBS AG",
          icon: "HomeIcon",
          variant: "secondary",
        },
        {
          text: "bank_address",
          value: "Loremstrasse 324 6300 Zug Switzerland",
          icon: "HomeIcon",
          variant: "info",
        },
        {
          text: "reference_number_payment_reason",
          value: "H3456 Hans Muller",
          icon: "DollarSignIcon",
          variant: "success",
        },
      ],
    };
  },
  props: ["deposit", "data"],
  created() {
    this.getTransactions();

    if (this.deposit) {
      this.amount = this.deposit.amount;
      // this.iban = this.deposit.iban;
      // this.bank_name = this.deposit.bank;
      // this.bank_address = this.deposit.bank_address;
      this.reference_number = this.deposit.reference_number;
    }
  },
  methods: {
    getTransactions() {
      this.$http.post("/transactions/admin").then((res) => {
        if (res) {
          this.transactions = res.data.value.data;
        }
      });
    },
    showSuccessMessage(message) {
      let text = this.$t(message);
      this.$swal({
        position: "center",
        icon: "success",
        title: text,
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((res) => {
        if (!this.deposit) {
          this.$emit("storeDeposit");
        }
      });
    },
    save() {
      let data = {
        amount: this.amount,
        // iban: this.iban,
        // bank: this.bank_name,
        // bank_address: this.bank_address,
        reference_number: this.reference_number,
        company_id: this.data.company_id,
      };
      if (this.deposit && this.deposit.id) {
        data.id = this.deposit.id;
      }

      this.$http.post("/transactions/admin/store", data).then((res) => {
        if (res) {
          this.showSuccessMessage("success");
        }
      });
    },
  },
};
</script>