<template>

  <b-alert :variant="checkStatus(data)" show>

    <b-row>
      <div class="col-md-12 row">
        <b-col class="pt-2 col-xl-6 col-lg-6 col-md-12">
          <div class="row col-md-12">
            <h4 class="ml-1 mr-1">{{ buyer}}</h4>
            <span>
              <feather-icon
                icon="CodesandboxIcon"
                class="text-success"
                size="20"
              ></feather-icon>
            </span>
          </div>
        </b-col>
        <b-col class="text-right pt-1 col-xl-6 col-lg-6 col-md-12">
          <b-button
            variant="primary"
            :disabled="nextStepStatus == 404"
            class="mr-1"
            @click="$emit('getNextStep')"
            >{{ $t("go_to_next_step") }}
          </b-button>
        </b-col>
      </div>
      <div class="col-md-12">
        <hr />
      </div>

      <div class="col-md-12 row">
        <b-col cols="12">
          <div class="row justify-content-between">
            <h4 align="start" class="col-xl-6 col-lg-6 col-md-12"></h4>
            <h4 class="col-xl-6 col-lg-6 col-md-12" align="end">
              <b-badge class="mb-1"> Next Step:  <br class="mobile"> {{ nextStepText }}</b-badge>
            </h4>
          </div>
        </b-col>
      </div>
    </b-row>
  </b-alert>
</template>

<script>
import { BAlert, BRow, BCol, BButton } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
export default {
  components: { BAlert, BRow, BCol, BButton },
  name: "MainAlert",
  props: [
    "data",
    "nextStepText",
    "role",
    "nextStep",
    "nextStepStatus",
    "buyer",
  ],
  data() {
    return {
      score: [],
    };
  },
  created() {

  },
  methods: {
    checkStatus(data){
       return 'success'
    },
    goToFunction() {
      this.$http
        .post(`/nextStep/changeStatus/${this.nextStep.id}`)
        .then((res) => {});
    },
  },
};
</script>
