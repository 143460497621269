<template>
    <b-card class="row-height" no-body>
        <div v-if="table">
            <b-row>
                <b-col>
                    <h2
                        class="
              text-blue
              font-weight-bold
              col-md-10 col-lg-10 col-xl-11
              mt-1
            "
                    >
                        {{ $t("transactions") }}
                    </h2>
                </b-col>
                <b-col align="end" class="col-md-6">
                    <b-button @click="actionButtonTab" variant="primary">
                        {{ $t("deposit") }}
                    </b-button>
                </b-col>
            </b-row>

            <b-table
                :items="transactions"
                striped
                responsive
                hover
                class="position-relative mt-2"
                show-empty
                :fields="fields"
            >
                <template #head()="scope">
                    <div class="text-nowrap">{{ $t(scope.label) }}</div>
                </template>

                <template #cell(id)="data">
                    <b-link @click="showTransaction(data.item)" style="width:55px;" class="badge badge-light-primary">
                        T-{{ data.item.id }}
                    </b-link>
                </template>
                <template #cell(amount)="data">
                    <span v-if="data.item.amount">
                        {{ amountFormat(data.item.amount, data.item.currency.name) }}
                        <feather-icon v-if="data.item.status == 0" @click="enableEdit(data.item)" icon="EditIcon" color="error" role="button"/>
                    </span>
                    <span v-else>-</span>

                </template>
                <template #cell(discount)="data">
                    <span v-if="data.item.discount">
                        {{ amountFormat(data.item.discount, data.item.currency.name) }}
                        <feather-icon v-if="data.item.status == 0" @click="enableEdit(data.item)" icon="EditIcon" color="error" role="button"/>
                    </span>
                    <span v-else>-</span>

                </template>
                <template #cell(currency)="data">
                    <span>{{ data.item.currency_text != null ? data.item.currency_text : 'CHF' }}</span>
                </template>
                <template #cell(actions)="data">
                    <span class="d-flex pa-0">
                        <b-button v-if="data.item.type_description == 'Withdraw'" @click="seeBankDetails(data.item)" variant="outline-primary" size="sm" class="mr-1" v-b-tooltip.hover :title="$t('bank_details')">
                        <feather-icon icon="InfoIcon"/></b-button>

                        <b-button v-if="data.item.status == 0" @click="approveTransaction(data.item)" variant="outline-success" size="sm" class="mr-1">
                        <feather-icon icon="CheckIcon"/></b-button>

                        <b-button @click="declineTransaction(data.item)" size="sm" v-if="data.item.status == 0" variant="outline-danger" class="mr-1">
                        <feather-icon icon="XIcon" color="error"/></b-button>
                        <b-button v-if="data.item.external_pdf_path != null" @click="showPDF(data.item.id)" size="sm" variant="outline-warning">
                            <FeatherIcon icon="BookOpenIcon"></FeatherIcon>
                        </b-button>
                  </span>

                </template>
                <template #cell(reference_number)="data">
                    <span v-if="data.item.reference_number" style="text-align: center">{{data.item.reference_number}}</span>
                    <span v-else>-</span>
                </template>

                <template #cell(status)="data">
                    <span v-if="data.value == 0">{{ $t("pending") }} </span>
                    <span v-if="data.value == 1">{{ $t("approved") }}</span>
                    <span v-if="data.value == 2">{{ $t("declined") }}</span>
                    <span class="text-secondary" v-if="data.value == 3">{{
            $t("proof_of_ownership_is_in_progress")
          }}</span>
                </template>
                <template #empty="scope">
                    <div>
                        {{ scope.emptyText }}
                    </div>
                </template>

                <template #cell(account_type)="data">
                    <span v-if="data.item.account_type">{{data.item.account_type}}</span>
                    <span v-else>-</span>
                </template>
                <template #cell(purpose_payment)="data">
                    <span v-if="data.item.purpose_payment">{{data.item.purpose_payment}}</span>
                    <span v-else>-</span>
                </template>
                <template #cell(account_owner_address)="data">
                    <span v-if="data.item.account_owner_address">{{data.item.account_owner_address}}</span>
                    <span v-else>-</span>
                </template>

            </b-table>
            <div class="col-md-12 row text-end justify-content-end" align="end">
                <b-pagination
                    @input="getTransactions"
                    :value="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="my-table"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mb-0"
                >
                    <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18"/>
                    </template>
                    <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18"/>
                    </template>
                </b-pagination>
            </div>


            <!--bank info modal-->

            <b-modal hide-footer v-model="bankDetailsModal" centered size="lg">
                <div>
                    <h4 class="font-weight-bolder mb-1">
                        {{ $t("your_bank_details") }}
                    </h4>

                    <span class="d-flex col-12">

                        <span class="col-4">{{ $t("account_owner") }}</span>

                        <span class="col-6">
                            <b-form-group
                                id="account_owner"
                                class="font-weight-bolder"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    :name="$t('account_owner')"
                                    rules="required"
                                >
                                    <b-form-input
                                        id="account_owner"
                                        v-model.trim="bankInfo.reference_person"
                                        disabled
                                        required
                                    ></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </span>
                        <h6>
                            <b-button @click="copyItem(bankInfo.reference_person)" variant="outline-primary" size="small" class="ml-2" id="copy-button">
                                <feather-icon icon="CopyIcon" class="primary" variant="primary" size="14"/>
                                <b-tooltip :target="'copy-button'" triggers="click" :show.sync="showCopiedTooltip" placement="top">
                                    Copied!
                                </b-tooltip>
                            </b-button>
                        </h6>
                    </span>

                    <span class="d-flex col-12">

                        <span class="col-4">{{ $t("iban") }}</span>

                        <span class="col-6">
                            <b-form-group
                                id="iban"
                                class="font-weight-bolder"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    :name="$t('iban')"
                                    rules="required"
                                >
                                    <b-form-input
                                        id="iban"
                                        v-model.trim="bankInfo.iban"
                                        disabled
                                        required
                                    ></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                        </span>
                        <h6>
                            <b-button @click="copyItem(bankInfo.iban)" variant="outline-primary" size="small" class="ml-2" id="copy-button">
                                <feather-icon icon="CopyIcon" class="primary" variant="primary" size="12"/>
                                <b-tooltip :target="'copy-button'" triggers="click" :show.sync="showCopiedTooltip" placement="top">
                                    Copied!
                                </b-tooltip>
                            </b-button>
                        </h6>
                    </span>

                    <span class="d-flex  col-12">

                        <span class="col-4">{{ $t("bank_name") }}</span>

                        <span class="col-6">
                            <b-form-group
                                id="bank_name"
                                class="font-weight-bolder"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    :name="$t('bank_name')"
                                    rules="required"
                                >
                                    <b-form-input
                                        id="amount-input"
                                        v-model.trim="bankInfo.bank"
                                        disabled
                                        required
                                    ></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </span>
                        <h6>
                            <b-button @click="copyItem(bankInfo.bank)" variant="outline-primary" size="small" class="ml-2" id="copy-button">
                                <feather-icon icon="CopyIcon" class="primary" variant="primary" size="14"/>
                                <b-tooltip :target="'copy-button'" triggers="click" :show.sync="showCopiedTooltip" placement="top">
                                    Copied!
                                </b-tooltip>
                            </b-button>
                        </h6>
                    </span>

                    <span class="d-flex col-12 ">

                        <span class="col-4">{{ $t("bic") }}</span>

                        <span class="col-6">
                            <b-form-group
                                id="bic"
                                class="font-weight-bolder"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    :name="$t('bic')"
                                    rules="required"
                                >
                                    <b-form-input
                                        id="bic-input"
                                        v-model.trim="bankInfo.bic"
                                        disabled
                                        required
                                    ></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </span>
                        <h6>
                            <b-button @click="copyItem(bankInfo.bic)" variant="outline-primary" size="small" class="ml-2" id="copy-button">
                                <feather-icon icon="CopyIcon" class="primary" variant="primary" size="14"/>
                                <b-tooltip :target="'copy-button'" triggers="click" :show.sync="showCopiedTooltip" placement="top">
                                    Copied!
                                </b-tooltip>
                            </b-button>
                        </h6>

                    </span>

                    <span class="d-flex col-12 ">

                        <span class="col-4">{{ $t("purpose_the_payment") }}</span>

                        <span class="col-6">
                            <b-form-group
                                id="purpose-payment"
                                class="font-weight-bolder"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    :name="$t('purpose_the_payment')"
                                    rules="required"
                                >
                                    <b-form-input
                                        id="purpose-input"
                                        v-model.trim="bankInfo.purpose_payment"
                                        disabled
                                        required
                                    ></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </span>
                        <h6>
                            <b-button @click="copyItem(bankInfo.purpose_payment)" variant="outline-primary" size="small" class="ml-2" id="copy-button">
                                <feather-icon icon="CopyIcon" class="primary" variant="primary" size="14"/>
                                <b-tooltip :target="'copy-button'" triggers="click" :show.sync="showCopiedTooltip" placement="top">
                                    Copied!
                                </b-tooltip>
                            </b-button>
                        </h6>

                    </span>

                    <span class="d-flex col-12 ">

                        <span class="col-4">{{ $t("account_owners_address") }}</span>

                        <span class="col-6">
                            <b-form-group
                                id="owner-address"
                                class="font-weight-bolder"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    :name="$t('account_owners_address')"
                                    rules="required"
                                >
                                    <b-form-input
                                        id="owner-address-input"
                                        v-model.trim="bankInfo.account_owner_address"
                                        disabled
                                        required
                                    ></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </span>
                        <h6>
                            <b-button @click="copyItem(bankInfo.account_owner_address)" variant="outline-primary" size="small" class="ml-2" id="copy-button">
                                <feather-icon icon="CopyIcon" class="primary" variant="primary" size="14"/>
                                <b-tooltip :target="'copy-button'" triggers="click" :show.sync="showCopiedTooltip" placement="top">
                                    Copied!
                                </b-tooltip>
                            </b-button>
                        </h6>

                    </span>

                    <span class="d-flex col-12 ">

                        <span class="col-4">{{ $t("reference_number") }}</span>

                        <span class="col-6">
                            <b-form-group
                                id="reference_number"
                                class="font-weight-bolder"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    :name="$t('reference_number')"
                                    rules="required"
                                >
                                    <b-form-input
                                        id="reference_number"
                                        v-model.trim="bankInfo.reference_number"
                                        disabled
                                        required
                                    ></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </span>
                        <h6>
                            <b-button @click="copyItem(bankInfo.reference_number)" variant="outline-primary" size="small" class="ml-2" id="copy-button">
                                <feather-icon icon="CopyIcon" class="primary" variant="primary" size="14"/>
                                <b-tooltip :target="'copy-button'" triggers="click" :show.sync="showCopiedTooltip" placement="top">
                                    Copied!
                                </b-tooltip>
                            </b-button>
                        </h6>

                    </span>

                    <span class="d-flex col-12 ">

                        <span class="col-4">{{ $t("reference_number") }}</span>

                        <span class="col-6">
                            <b-form-group
                                id="reference_number"
                                class="font-weight-bolder"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    :name="$t('reference_number')"
                                    rules="required"
                                >
                                    <b-form-input
                                        id="reference_number"
                                        v-model.trim="bankInfo.reference_number"
                                        disabled
                                        required
                                    ></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </span>
                        <h6>

                            <b-button @click="copyItem(bankInfo.reference_number)" variant="outline-primary" size="small" class="ml-2" id="copy-button">
                                <feather-icon icon="CopyIcon" class="primary" variant="primary" size="14"/>
                                <b-tooltip :target="'copy-button'" triggers="click" :show.sync="showCopiedTooltip" placement="top">
                                    Copied!
                                </b-tooltip>
                            </b-button>
                        </h6>
                    </span>


                    <span class="d-flex col-12 ">

                        <span class="col-4">PDF</span>

                        <span class="col-6">

                        </span>
                        <h6>
                            <b-button v-if="bankInfo.external_pdf_path != null" @click="showPDF(bankInfo.id)" variant="outline-primary" size="small" class="ml-2" id="copy-button">
                                <FeatherIcon icon="BookOpenIcon"></FeatherIcon>
                            </b-button>
                        </h6>
                    </span>


                </div>
            </b-modal>

            <b-modal
                v-model="editModal"
                style="padding-top: 10vh;"
                scrollable
                no-close-on-backdrop
                hide-header-close
                size="sm"
            >
                <template #modal-footer="{}">

                    <b-button @click="editModal = false" size="sm" variant="outline-primary">
                        {{ $t("back") }}
                    </b-button>
                    <b-button size="sm" variant="primary" @click="editNewAmount(item)">
                        {{ $t("save") }}
                    </b-button>
                </template>
                <div>

    <span>
      <b-form-group
          id="amount"
          class="font-weight-bolder"
          :label="$t('amount')"
          label-for="amount"
      >
        <validation-provider
            #default="{ errors }"
            :name="$t('amount')"
            rules="required"
        >
         <b-form-input
             id="amount-input"
             v-model.trim="amount"
             type="number"
             required
         ></b-form-input>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
     </b-form-group>
    </span>

                </div>

            </b-modal>
        </div>

        <Deposit
            v-else
            @showTable="showTable"
            :data="data"
            :deposit="deposit"
            @storeDeposit="storeDeposit"
        ></Deposit>
    </b-card>
</template>
<script>

  import Deposit from "./Depoist.vue";
  import {ValidationObserver, ValidationProvider} from "vee-validate";
  import { CurrencyAmountFormat } from '@core/utils/CurrencyAmountFormat';

  export default {
    components: {Deposit, ValidationProvider, ValidationObserver},
    data() {
      return {
        url: "/transactions/admin",
        button: {
          text: "deposit",
          link: "",
          color: "primary",
        },
        fields: [
          {
            key: "id",
            label: "id",
          },
          {
            key: "type_description",
            label: "description",
          },
          {
            key: "currency",
            label: "currency",
          },
          {
            key: "amount",
            label: "amount",
          },
          {
            key: "discount",
            label: "discount",
          },
          {
            key: "discount_percentage",
            label: "discount percentage",
          },
          {
            key: "status",
            label: "status",
          },
          {
            key: "reference_number",
            label: "reference_number_payment_reason",
          },
          {
            key: "account_type",
            label: 'account_type',
          },
          {
            key: "purpose_payment",
            label: 'purpose_payment',
          },
          {
            key: "account_owner_address",
            label: 'account_owner_address',
          },
          {
            key: "actions",
            label: "actions",
          },
        ],
        table: true,
        deposit: null,
        transactions: [],
        body: {
          company_id: this.$route.params.id,
        },
        totalItems: 0,
        currentPage: 1,
        perPage: 12,
        bankDetailsModal: false,
        bankInfo: {
          bank: null,
          iban: null,
          reference_number: null,
          reference_person: null,
          bic: null,
        },
        showCopiedTooltip: false,
        editModal: false,
        amount: null,
        itemToEdit: null,
      };
    },
    props: ["companyData", "data", "makeCall"],

    watch: {
      makeCall(newValue, oldValue) {
        if (newValue !== oldValue && this.makeCall) {
          this.getTransactions();
        }
      },
    },
    computed: {
      rows() {
        return this.totalItems;
      },
    },
    created() {
      this.getTransactions();
    },
    methods: {
      amountFormat(amount, currency) {
        return CurrencyAmountFormat(amount, currency)
      },

      showPDF(id) {
        this.$http
            .post(`/transactions/admin/show/pdf/${id}`)
            .then((response) => {
              this.$store.commit("app/SET_FILE_TYPE", response.data.value.ext);
              this.$store.commit("app/SET_FILE_BASE64", response.data.value.b64);
              window.open("/read-files", "_blank");

            })
      },

      enableEdit(item) {
        this.itemToEdit = item;
        this.editModal = true;

      },
      editNewAmount(item) {
        item = this.itemToEdit;
        this.itemToEdit.amount = this.amount;
        this.$http
            .post(`/transactions/admin/${item.id}`, {amount: item.amount})
            .then((res) => {
              if (res) {
                if (res.data.status == 200) {
                  this.$swal({
                    position: "center",
                    icon: "success",
                    title: 'Success',
                    showConfirmButton: false,
                    timer: 1500,
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                  });
                  this.itemToEdit = null;
                } else {
                  this.$swal({
                    position: "center",
                    icon: "error",
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 1500,
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                  });
                }

                this.getTransactions();
              }
            });
        this.editModal = false;
      },
      seeBankDetails(item) {
        this.bankInfo = item;
        console.log(this.bankInfo, 111);
        this.bankDetailsModal = true;
      },

      approveTransaction(item) {
        this.$http
            .post(`/transactions/admin/${item.id}/1`)
            .then((res) => {
              if (res) {
                if (res.data.status == 200) {
                  this.$swal({
                    position: "center",
                    icon: "success",
                    title: 'Success',
                    showConfirmButton: false,
                    timer: 1500,
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                  });
                } else {
                  this.$swal({
                    position: "center",
                    icon: "error",
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 1500,
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                  });
                }
                window.location.reload();
                this.getTransactions();
              }
            });
      },

      declineTransaction(item) {
        this.$http
            .post(`/transactions/admin/${item.id}/2`)
            .then((res) => {
              if (res) {
                if (res.data.status == 200) {
                  this.$swal({
                    position: "center",
                    icon: "success",
                    title: 'Success',
                    showConfirmButton: false,
                    timer: 1500,
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                  });
                } else {
                  this.$swal({
                    position: "center",
                    icon: "error",
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 1500,
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                  });
                }
                window.location.reload();
                this.getTransactions();
              }
            });
      },
      storeDeposit() {
        this.getTransactions();
      },
      showTransaction(item) {
        this.deposit = item;
        this.table = false;
      },
      actionButtonTab() {
        this.deposit = null;
        this.table = false;
      },

      showTable() {
        this.table = true;
      },
      getTransactions() {
        this.$http
            .post(`/transactions/admin?page=${this.currentPage}`, {
              company_id: this.data.company_id,
            })
            .then((res) => {
              if (res) {
                console.log(res);
                this.transactions = res.data.value.data;
                this.table = true;
                this.totalItems = res.data.value.total;
                this.currentPage = res.data.value.current_page;
              }
            });
      },
      copyToClipboard(text) {
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        this.$set(this.showCopiedTooltip, 0, true); // Assuming you always have one item
        setTimeout(() => {
          this.$set(this.showCopiedTooltip, 0, false);
        }, 1000);
      },

      copyItem(text) {
        if (!text) {
          return;
        }

        navigator.clipboard.writeText(text).then(() => {
          this.showCopiedTooltip = true;
          setTimeout(() => {
            this.showCopiedTooltip = false;
          }, 1000);
        }).catch((err) => {
          console.error('Copy to clipboard failed:', err);
        });
      }

    },
  };
</script>
