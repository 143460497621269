<template>
  <div v-if="!load">
    <Alert
      :nextStep="nextStep"
      :nextStepText="nextStepText"
      :nextStepStatus="nextStepStatus"
      :data="data"
      :buyer="buyer"
      @getNextStep="checkNexStep"
    ></Alert>

    <b-tabs content-class="pt-1" fill v-model="tabIndex" v-if="data">
      <b-tab @click="getData('buyer')">
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>{{ $t("buyer") }}</span>
        </template>
        <b-card>
          <UpdateForm
            :makeCall="makeCall.buyer"
            :company="companyData"
            @updateData="getCompany"
          />
        </b-card>
      </b-tab>

      <b-tab @click="getData('owners')">
        <template #title>
          <feather-icon icon="UsersIcon" />
          <span>{{ $t("beneficial_owner") }}</span>
        </template>
        <BeneficialOwners :makeCall="makeCall.owners" />
      </b-tab>
      <b-tab @click="getData('tasks')">
        <template #title>
          <feather-icon icon="ListIcon" />
          <span>{{ $t("tasks") }}</span>
        </template>

        <Tasks
          :makeCall="makeCall.tasks"
          :data="data"
          @updateData="getCompany"
        />
      </b-tab>
      <b-tab @click="getData('account')">
        <template #title>
          <feather-icon icon="CheckSquareIcon" />
          <span>{{ $t("account_summary") }}</span>
        </template>
        <AccountSummary :makeCall="makeCall.account" />
      </b-tab>
      <b-tab @click="getData('documents')">
        <template #title>
          <feather-icon icon="FileIcon" />
          <span>{{ $t("documents") }}</span>
        </template>
        <Documents :makeCall="makeCall.documents" />
      </b-tab>
      <b-tab @click="getData('transactions')">
        <template #title>
          <feather-icon icon="CreditCardIcon" />
          <span>{{ $t("transactions") }}</span>
        </template>
        <b-card>
          <Transactions
            :data="data"
            :makeCall="makeCall.transactions"
          ></Transactions>
        </b-card>
      </b-tab>
      <b-tab @click="getData('bod')">
        <template #title>
          <feather-icon icon="FileTextIcon" />
          <span>{{ $t("BoD") }}</span>
        </template>
        <b-card>
          <BoD
            :data="data"
            :makeCall="makeCall.bod"
          ></BoD>
        </b-card>
      </b-tab>
    </b-tabs>
  </div>
  <div v-else>
    <img src="/loader.svg" />
  </div>
</template>
<script>
import UpdateForm from "./BuyerDetails.vue";
import Documents from "./Documents.vue";
import AccountSummary from "./AccountSummary.vue";
import BeneficialOwners from "./BeneficialOwners.vue";
import Tasks from "./Tasks.vue";
import Transactions from "./Transactions.vue";
import Alert from "./MainAlert.vue";
import BoD from "./BoD.vue"

export default {
  components: {
    UpdateForm,
    Documents,
    AccountSummary,
    BeneficialOwners,
    Tasks,
    Transactions,
    Alert,
    BoD
  },
  data() {
    return {
      tabIndex: 0,
      buyer: null,
      data: null,
      nextStep: "",
      nextStepStatus: "",
      nextStepText: "",
      companyData: null,
      load: true,
      makeCall: {
        buyer: false,
        owners: false,
        account: false,
        documents: false,
        transactions: false,
        tasks: false,
      },
    };
  },
  created() {
    if(this.$route.hash && this.$route.hash == '#transactions'){
      this.tabIndex = 5
    }
    this.getUser();
    // this.getUser();
    this.getData("buyer");
  },
  methods: {
    getData(type) {
      this.makeCall = {
        buyer: false,
        owners: false,
        account: false,
        documents: false,
        transactions: false,
        tasks: false,
        bod: false,
      };
      for (var prop in this.makeCall) {
        if (prop == type) {
          this.makeCall[prop] = true;
        }
      }
      console.log('type',type)
    },
    completeTask() {
      if (this.nextStepText.id) {
        this.$http
          .post(`/nextStep/changeStatus/${this.nextStep.id}`)
          .catch((err) => {
            this.load = false;
          })
          .then((res) => {
            this.nextStep = res.data.value;

            if (this.nextStep.tab == "Transactions") {
              this.tabIndex = 5;
            }
            if (this.nextStep.tab == "Documents") {
              this.tabIndex = 4;
            }
            if (this.nextStep.tab == "Account Summary") {
              this.tabIndex = 3;
            }
            if (this.nextStep.tab == "Buyer") {
              this.tabIndex = 0;
            }

            if (res.data.message) {
              this.nextStepText = res.data.message;
            }

            // this.getCompany();
          });
      }
    },

    getCompany(tab) {
      this.load = true;
      this.$http
        .post(`/company/admin/show/${this.data.company_id}`)
        .then((res) => {
          if (res && res.data && res.data.value) {
            this.getNexStep();
            this.companyData = res.data.value;
            this.$forceUpdate();

            if (tab && tab == "Buyer") {
              this.tabIndex = 0;
              this.completeTask();
            }
            if (tab && tab == "Account Summary") {
              this.tabIndex = 3;
              this.completeTask();
            }

            if (tab && tab == "Documents") {
              this.tabIndex = 4;
              this.completeTask();
            }
            if (tab && tab == "Transactions") {
              this.tabIndex = 5;
              this.completeTask();
            }
            if (tab && tab == "tasks") {
              this.getData('tasks');
              this.completeTask();
            }
            if (tab && tab == "bod") {
              this.getData('bod');
              this.completeTask();
            }
          }
          this.load = false;
        });
    },
    getUser() {
      this.$http
        .post(`/buyer/admin/show/${this.$route.params.id}`, {})
        .then((res) => {
          if (res) {
            this.data = res.data.value;
            this.data.role = "debtor";
            this.buyer =
              res.data.value.first_name + " " + res.data.value.last_name;

            this.getCompany("created");
          }
        });
    },

    checkNexStep(type) {
      this.load = true;
      this.$http
        .post(`/nextStep/show/${this.data.company_id}`)
        .then((res) => {
          if (res.data.value) {
            this.nextStep = res.data.value;
            this.nextStepText = res.data.value.title;
          } else if (res.data.message) {
            this.nextStepText = res.data.message;
          }
          this.nextStepStatus = res.data.status;
          this.load = false;

          if (type != "created" && this.nextStep) {
            if (this.nextStep.tab == "Buyer") {
              this.tabIndex = 0;
            }
            if (this.nextStep.tab == "Account Summary") {
              this.tabIndex = 3;
            }
            if (this.nextStep.tab == "Documents") {
              this.tabIndex = 4;
            }
            if (this.nextStep.tab == "Transactions") {
              this.tabIndex = 5;
            }
          }
        })
        .catch((err) => {
          this.load = false;
        });
    },

    getNexStep() {
      this.load = true;
      this.$http
        .post(`/nextStep/show/${this.data.company_id}`)
        .then((res) => {
          if (res.data.value) {
            this.nextStep = res.data.value;
            this.nextStepText = res.data.value.title;
          } else if (res.data.message) {
            this.nextStepText = res.data.message;
          }
          this.nextStepStatus = res.data.status;
          this.load = false;
        })
        .catch((err) => {
          this.load = false;
        });
    },
  },
};
</script>