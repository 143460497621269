<template>
  <div>
    <b-card>
      <h3 class="light-blue">{{ $t("activate_account_by_verifying") }}</h3>
      <br />
      <h5>{{ $t("your_details") }}</h5>
      <br />
      <app-timeline>
        <!-- 12 INVOICES HAVE BEEN PAID -->
        <app-timeline-item variant="primary">
          <h5 class="col-md-12">{{ $t("personal_info") }}</h5>
          <div
            class="
              d-flex
              flex-sm-row flex-column flex-wrap
              justify-content-between
              mb-1 mb-sm-0
            "
          >
            <h6 class="row col-md-8 light-blue">
              <b-col cols="12" xl="6" md="6" lg="6">
                <small>
                  <span
                    v-html="main_contact_gender == 0 ? $t('mr') : $t('mrs')"
                  ></span>
                  {{ main_contact_first_name }}</small
                >
                <br />
                <small>
                  {{ main_contact_address }}
                  {{ main_contact_address_nr }}</small
                >
                <br />
                <small> {{ main_contact_zip }} {{ main_contact_city }}</small>

                <br />
                <br />
                <span class="text-gray"> {{ $t("occupation") }}:</span>
                <br />
                <small>{{ main_contact_position }} </small>
                <br />
                <br />
                <span class="text-gray"> {{ $t("industry") }}: </span> <br />
                <small> {{ main_contact_industry }} </small>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
                <span class="text-gray"> {{ $t("born") }}: </span> <br />
                <small>
                  {{ main_contact_birthday ? main_contact_birthday : "" }}
                </small>
                <br />
                <br />
                <span class="text-gray"> {{ $t("nationality") }}: </span> <br />
                <small> {{ main_contact_nationality }}</small>
                <br />
                <br />
                <span class="text-gray"> {{ $t("email") }}: </span>
                <br /><small>
                  {{ main_contact_email }}
                </small>
              </b-col>
            </h6>
          </div>
        </app-timeline-item>
        <app-timeline-item variant="primary">
          <div
            class="
              d-flex
              flex-sm-row flex-column flex-wrap
              justify-content-between
              mb-1 mb-sm-0
            "
          >
            <h6 class="row col-md-6">
              <b-col cols="12">
                <h5>{{ $t("financial_situation") }}</h5>
                <small
                  v-for="(item, index) in financial_questions"
                  :key="index"
                >
                  {{ item.question }}:
                  <strong v-if="item.answer == 1">{{ $t("yes") }}</strong>
                  <strong v-if="item.answer == 0">{{ $t("no") }}</strong>
                  <br />
                </small>
              </b-col>
            </h6>
          </div>
        </app-timeline-item>

        <app-timeline-item variant="primary">
          <div
            class="
              d-flex
              flex-sm-row flex-column flex-wrap
              justify-content-between
              mb-1 mb-sm-0
            "
          >
            <h6 class="row col-md-6">
              <b-col cols="12">
                <h5>{{ $t("qualification_as_pep") }}</h5>

                <small
                  v-for="(item, index) in qualification_questions"
                  :key="index"
                >
                  {{ item.question }}:
                  <strong v-if="item.answer == 1">{{ $t("yes") }}</strong>
                  <strong v-if="item.answer == 0">{{ $t("no") }}</strong>
                  <br />
                </small>
              </b-col>
            </h6>
          </div>
        </app-timeline-item>

        <app-timeline-item variant="primary">
          <div class="">
            <h5 class="col-md-12" v-if="beneficial == 1">
              {{ $t("i_am_beneficial_owner") }}
            </h5>
            <h5 class="col-md-12" v-if="beneficial != 1">
              {{ $t("following_person_is_beneficial_owner") }}
            </h5>
            <h6
              class="row col-md-8"
              v-if="beneficial != 1"
              v-for="(user, index) in owners"
              :key="index"
            >
              <b-col cols="12" xl="6" md="6" lg="6">
                <br />
                <small>
                  {{ user.first_name }} {{ user.last_name }}
                  <br />
                  {{ user.address }} {{ user.address_nr }}
                  <br />
                  {{ user.zip }} {{ user.city }}, {{ user.country.name }}</small
                ><br />
                <small class="font-weight-bolder text-gray">
                  {{ $t("born") }}:
                </small>
                <small> {{ user.birthday | formatDateWithoutTime }}</small>
                <br />
                <small class="font-weight-bolder text-gray">
                  {{ $t("nationality") }}:
                </small>
                <small> {{ user.nationality }}</small>
                <br />
              </b-col>
            </h6>

            <h6 class="row col-md-8 light-blue" v-if="beneficial == 1">
              <b-col cols="12" xl="6" md="6" lg="6">
                <small>
                  <span
                    v-html="main_contact_gender == 0 ? $t('mr') : $t('mrs')"
                  ></span>
                  {{ main_contact_first_name }}</small
                >
                <br />
                <small>
                  {{ main_contact_address }}
                  {{ main_contact_address_nr }}</small
                >
                <br />
                <small>
                  {{ main_contact_zip }} {{ main_contact_city }}
                  {{ main_contact_country }}</small
                >

                <br />
                <small
                  ><strong class="text-gray font-weight-bolder"
                    >{{ $t("born") }}:</strong
                  >
                  {{ main_contact_birthday ? main_contact_birthday : "" }}
                </small>
                <br />
                <small
                  ><strong class="text-gray font-weight-bolder"
                    >{{ $t("nationality") }}:</strong
                  >
                  {{ main_contact_nationality }}</small
                >
              </b-col>
            </h6>
          </div>
        </app-timeline-item>

        <app-timeline-item variant="primary">
          <div
            class="
              d-flex
              flex-sm-row flex-column flex-wrap
              justify-content-between
              mb-1 mb-sm-0
            "
          >
            <h6 class="col-md-6">
              <h5>{{ $t("mTan_confirmation_title") }}</h5>

              <small>{{ $t("mTan_confirmation_text_1_before_link") }} </small>

              <router-link
                to="/files/terms_de"
                target="_blank"
                v-if="$store.state.app.language == 'de'"
                >{{ $t("mTan_confirmation_text_1_link") }}</router-link
              >
              <router-link to="/files/terms" target="_blank" v-else>{{
                $t("mTan_confirmation_text_1_link")
              }}</router-link>

              <small> {{ $t("mTan_confirmation_text_1_after_link") }}</small>
              <br />
              <small>{{ $t("mTan_confirmation_text_2") }}</small>
              <br />
            </h6>
          </div>
        </app-timeline-item>
        <app-timeline-item variant="primary">
          <div class="mb-1 mb-sm-0">
            <h5 class="col-md-12">
              {{ $t("your_phone_number") }}
            </h5>
            <small class="col-md-12">{{ main_contact_tel }}</small>
          </div>
        </app-timeline-item>

        <app-timeline-item variant="primary" v-if="mtanValue">
          <div class="mb-1 mb-sm-0">
            <h5 class="col-md-6 text-success">
              <feather-icon
                icon="CheckIcon"
                class="text-success"
                variant="success"
                size="21"
              />
              {{ $t("mtan_before_number_text") }}
              {{ mtanValue }}
              {{ $t("mtan_after_number_text") }}
              <br />
            </h5>
          </div>
        </app-timeline-item>
      </app-timeline>

      <br />
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { VueAutosuggest } from "vue-autosuggest";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {
  BAlert,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormRadioGroup,
  BFormSelect,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
} from "bootstrap-vue";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BFormCheckbox,
    BFormDatepicker,
    BFormRadio,
    BFormRadioGroup,
    BAlert,
    BInputGroupPrepend,
    ValidationProvider,
    ValidationObserver,
    VueAutosuggest,
    AppTimeline,
    AppTimelineItem,
  },
  name: "ActivateAccount",
  data() {
    return {
      beneficial: 1,
      mTan: null,
      mTanSent: false,
      type: "private",
      countries: [],
      showBlueBorder: false,
      nationalities: ["Switzerland", "Albanian", "Macedonian", "German"],
      positions: [
        this.$t("employed"),
        this.$t("self-employed"),
        this.$t("retired"),
        this.$t("etc"),
      ],
      main_contact_gender: "",
      main_contact_email: "",
      main_contact_iso_code: "",
      main_contact_first_name: "",
      main_contact_last_name: "",
      main_contact_birthday: "",
      main_contact_nationality: "",
      main_contact_zip: "",
      main_contact_address: "",
      main_contact_address_nr: "",
      main_contact_city: "",
      main_contact_country_id: "",
      main_contact_tel: "",
      main_contact_position: "",
      main_contact_industry: "",
      main_contact_country: null,
      financial_questions: [
        { id: 1, question: this.$t("question_1"), answer: false },
        { id: 2, question: this.$t("question_2"), answer: false },
        { id: 3, question: this.$t("question_3"), answer: false },
      ],
      qualification_questions: [
        { id: 4, question: this.$t("question_4"), answer: false },
        { id: 5, question: this.$t("question_5"), answer: false },
        { id: 6, question: this.$t("question_6"), answer: false },
        { id: 7, question: this.$t("question_7"), answer: false },
      ],
      terms_and_conditions: false,
      disableInputs: false,
      owners: [],
      company_id: "",
      mtanValue: "",
    };
  },
  props: ["makeCall"],
  created() {
    // this.getUser();
  },
  watch: {
    makeCall(newValue, oldValue) {
      if (newValue !== oldValue && this.makeCall) {
        this.loader = true;

        this.$http.post("/countries").then((res) => {
          res.data.value.map((item) => {
            this.countries.push({
              value: item.id,
              text: item.name,
            });
          });

          this.getUser();
        });
        this.loader = false;
      }
    },
  },

  methods: {
    activateAccount() {
      this.$http
        .post(`/buyer/admin/activate-account/${this.company_id}`)
        .then((res) => {
          if (res) {
            let mess = "buyer_account_activated_successfully";

            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t(mess),
                icon: "SuccessIcon",
                variant: "success",
              },
            });
          }
        });
    },
    getUser() {
      this.$http
        .post(`/buyer/admin/show/${this.$route.params.id}`, {})
        .then((res) => {
          if (res.data.value) {
            this.company_id = res.data.value.company_id;
            this.beneficial = res.data.value.beneficial;
            this.owners = res.data.value.owners;

            this.countries.forEach((el) => {
              this.owners.forEach((owner) => {
                if (el.value.toString() == owner.nationality) {
                  owner.nationality_id = el.value;
                  owner.nationality = el.text;
                }
              });
            });
            this.main_contact_gender = res.data.value.gender;
            this.main_contact_first_name = res.data.value.name;
            this.main_contact_last_name = res.data.value.last_name;
            this.main_contact_position =
              this.positions[res.data.value.position - 1];
            this.main_contact_iso_code = res.data.value.iso_code;
            this.main_contact_tel = res.data.value.tel;
            this.main_contact_email = res.data.value.email;

            this.main_contact_address = res.data.value.address;
            this.main_contact_address_nr = res.data.value.address_nr;
            this.main_contact_birthday = res.data.value.birthday;
            this.main_contact_nationality = res.data.value.nationality;
            this.main_contact_industry = res.data.value.industry;
            this.main_contact_zip = res.data.value.zip;
            this.main_contact_city = res.data.value.city;
            this.main_contact_country = res.data.value.country_name;
            this.mtanValue = res.data.value.mTan;
          }
          if (res.data.value.answers) {
            this.financial_questions.map((item) => {
              item.answer =
                res.data.value.answers["financial_answer_" + item.id];
            });
            this.qualification_questions.map((item) => {
              item.answer =
                res.data.value.answers["qualification_answer_" + item.id];
            });
          }
        });
    },
    saveBuyerInfo() {
      this.$refs.buyerRegisterValidation.validate().then((success) => {
        if (success) {
          this.disableInputs = true;
          const formSubmissionData = {
            gender: this.main_contact_gender,
            first_name: this.main_contact_first_name,
            last_name: this.main_contact_last_name,
            position: this.main_contact_position,
            tel: this.main_contact_tel,
            address: this.main_contact_address,
            address_nr: this.main_contact_address_nr,
            birthday: this.main_contact_birthday,
            nationality: this.main_contact_nationality,
            country_id: this.main_contact_country_id,
            industry: this.main_contact_industry,
            zip: this.main_contact_zip,
            city: this.main_contact_city,
          };
          let data = {};
          this.financial_questions.map((item) => {
            const financial_question = {
              ["financial_answer_" + item.id]: item.answer,
            };
            data = { ...data, ...financial_question };
          });
          this.qualification_questions.map((item) => {
            const qualification_question = {
              ["qualification_answer_" + item.id]: item.answer,
            };
            data = { ...data, ...qualification_question };
          });
          data = { ...data, ...formSubmissionData };

          this.$http.post("/buyer/store", data).then((res) => {
            let mess = "";
            let icon = "";
            let variant = "";
            if (res && res.data.status == 500) {
              mess = res.data.message;
              icon = "ErrorIcon";
              variant = "error";
            } else {
              mess = "buyer_registered_successfully";
              icon = "SuccessIcon";
              variant = "success";
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t(mess),
                icon: icon,
                variant: variant,
              },
            });
            this.$router.push("/");
          });
        }
        this.disableInputs = false;
      });
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("saved"),
          icon: "ErrorIcon",
          variant: "error",
        },
      });
    },
    notActiveNow() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("not_yet_active_for_companies"),
          icon: "ErrorIcon",
          variant: "error",
        },
      });
    },
    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onReset(event) {
      event.preventDefault();
      this.country_id = null;
    },
  },
};
</script>

<style>
.text-gray {
  color: #5e5873;
}
</style>
