var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('h3',{staticClass:"light-blue"},[_vm._v(_vm._s(_vm.$t("beneficial_owner")))]),_c('b-card-text',{staticClass:"mt-2"},[_c('validation-observer',{ref:"confirmBeneficialOwner",attrs:{"name":"ConfirmBeneficialOwner"}},[(_vm.errors.length > 0)?_c('b-row',{staticClass:"col-md-12 pr-0 mr-0"},[_c('div',{staticClass:"alert alert-danger col-md-12 pr-0"},[_c('div',{staticClass:"p-2"},_vm._l((_vm.errors),function(err,index){return _c('div',{key:index},[_c('b-avatar',{attrs:{"variant":"light-danger","size":"45"}},[_c('feather-icon',{attrs:{"size":"21","icon":"AlertTriangleIcon"}})],1),_vm._v(" "+_vm._s(err)+" ")],1)}),0)])]):_vm._e(),_c('b-form',{on:{"submit":_vm.onSubmit,"reset":_vm.onReset}},[_c('b-card',{staticClass:"col-md-12"},[_c('span',{staticClass:"beneficial-owner-title row col-md-12"},[_vm._v(_vm._s(_vm.$t("source_of_funds")))]),_c('br'),_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":"source-of-funds","label":_vm.$t('source_of_funds') + ':',"label-for":"domicile-country"}},[_c('validation-provider',{attrs:{"name":"SourceOfFunds","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"source-of-funds","options":_vm.source_of_funds_items,"state":errors.length > 0 ? false : null,"required":"","disabled":_vm.disableInputs},model:{value:(_vm.source_of_funds),callback:function ($$v) {_vm.source_of_funds=$$v},expression:"source_of_funds"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":"planned-investment-amount","label":_vm.$t('planned_investment_amount') + ':',"label-for":"planned-investment-amount"}},[_c('validation-provider',{attrs:{"name":"PlannedInvestmentAmount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"planned-investment-amount","options":_vm.investment_amount_items,"state":errors.length > 0 ? false : null,"required":"","disabled":_vm.disableInputs},model:{value:(_vm.investment_amount),callback:function ($$v) {_vm.investment_amount=$$v},expression:"investment_amount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('br'),_c('br'),_c('br'),_c('span',{staticClass:"beneficial-owner-title row col-md-12"},[_vm._v(_vm._s(_vm.$t("beneficial_owner")))]),_c('span',{staticClass:"row col-md-12"},[_vm._v(" "+_vm._s(_vm.$t("beneficial_owner_subtitle"))+" ")]),_c('br'),_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"12","xl":"12","md":"12","lg":"12"}},[_c('b-button',{staticClass:"mb-2",attrs:{"variant":_vm.beneficial_owner_selected_option == 1
                    ? 'primary'
                    : 'outline-primary'},on:{"click":function($event){return _vm.changeBeneficialOwnerOption(1)}}},[_vm._v(_vm._s(_vm.$t("i_am_beneficial_owner"))+" ")]),_c('b-button',{staticClass:"mb-2 ml-1",attrs:{"variant":_vm.beneficial_owner_selected_option == 2
                    ? 'primary'
                    : 'outline-primary'},on:{"click":function($event){return _vm.changeBeneficialOwnerOption(2)}}},[_vm._v(" "+_vm._s(_vm.$t("following_person_is_beneficial_owner"))+" ")]),(_vm.beneficial_owner_selected_option == 1)?_c('div',{staticClass:"others-as-beneficial-owner-box p-1 pt-2"},[_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio',{attrs:{"aria-describedby":ariaDescribedby,"name":"some-radios","value":_vm.beneficial_radio == 1 ? 1 : 0},model:{value:(_vm.beneficial_radio),callback:function ($$v) {_vm.beneficial_radio=$$v},expression:"beneficial_radio"}},[(
                        _vm.beneficial_owner_selected_option_main_user &&
                        _vm.beneficial_owner_selected_option_main_user.first_name
                      )?_c('span',[_vm._v(_vm._s(_vm.beneficial_owner_selected_option_main_user.first_name)+" ")]):_vm._e(),(
                        _vm.beneficial_owner_selected_option_main_user &&
                        _vm.beneficial_owner_selected_option_main_user.last_name
                      )?_c('span',[_vm._v(_vm._s(_vm.beneficial_owner_selected_option_main_user.last_name)+" ")]):_vm._e(),_vm._v(", "),(
                        _vm.beneficial_owner_selected_option_main_user &&
                        _vm.beneficial_owner_selected_option_main_user.address
                      )?_c('span',[_vm._v(_vm._s(_vm.beneficial_owner_selected_option_main_user && _vm.beneficial_owner_selected_option_main_user.address)+" ")]):_vm._e(),(
                        _vm.beneficial_owner_selected_option_main_user &&
                        _vm.beneficial_owner_selected_option_main_user.address_nr
                      )?_c('span',[_vm._v(" "+_vm._s(_vm.beneficial_owner_selected_option_main_user.address_nr)+" ")]):_vm._e(),_vm._v(", "),(
                        _vm.beneficial_owner_selected_option_main_user &&
                        _vm.beneficial_owner_selected_option_main_user.zip
                      )?_c('span',[_vm._v(" "+_vm._s(_vm.beneficial_owner_selected_option_main_user.zip)+" ")]):_vm._e(),(
                        _vm.beneficial_owner_selected_option_main_user &&
                        _vm.beneficial_owner_selected_option_main_user.city
                      )?_c('span',[_vm._v(" "+_vm._s(_vm.beneficial_owner_selected_option_main_user.city)+" ")]):_vm._e(),(
                        _vm.beneficial_owner_selected_option_main_user &&
                        _vm.beneficial_owner_selected_option_main_user.birthday
                      )?_c('span',[_vm._v(","+_vm._s(_vm.$t("born"))+" "+_vm._s(_vm.beneficial_owner_selected_option_main_user.birthday)+" ")]):_vm._e(),(
                        _vm.beneficial_owner_selected_option_main_user &&
                        _vm.beneficial_owner_selected_option_main_user.nationality
                      )?_c('span',[_vm._v(","+_vm._s(_vm.$t("nationality"))+". "+_vm._s(_vm.beneficial_owner_selected_option_main_user.nationality)+" ")]):_vm._e()])]}}],null,false,1777009960)})],1):_vm._e(),_c('br'),(_vm.beneficial_owner_selected_option == 2)?_c('div',_vm._l((_vm.other_as_beneficial_owner_items),function(item,index){return _c('div',{key:index,staticClass:"others-as-beneficial-owner-box"},[_c('div',[_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('div',{staticClass:"col-md-12",attrs:{"align":"end"}},[_c('b-btn',{attrs:{"variant":"danger","align":"end","size":"sm"},on:{"click":function($event){return _vm.removeBeneficialOwner(index)}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('first_name')}},[_c('validation-provider',{attrs:{"name":'first_name',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.disableInputs,"id":'first_name_' + index},model:{value:(item.first_name),callback:function ($$v) {_vm.$set(item, "first_name", $$v)},expression:"item.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('last_name')}},[_c('validation-provider',{attrs:{"name":'last_name',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.disableInputs,"id":'last_name_' + index},model:{value:(item.last_name),callback:function ($$v) {_vm.$set(item, "last_name", $$v)},expression:"item.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('birthday')}},[_c('validation-provider',{attrs:{"name":'birthday',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.disableInputs,"type":"date","id":'birthday_' + index},model:{value:(item.birthday),callback:function ($$v) {_vm.$set(item, "birthday", $$v)},expression:"item.birthday"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('nationality')}},[_c('validation-provider',{attrs:{"name":'nationality',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-select',{attrs:{"disabled":_vm.disableInputs,"options":_vm.countries,"type":"date","id":'nationality_' + index},model:{value:(item.nationality),callback:function ($$v) {_vm.$set(item, "nationality", $$v)},expression:"item.nationality"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"9"}},[_c('b-form-group',{attrs:{"label":_vm.$t('street_name')}},[_c('validation-provider',{attrs:{"name":'street_name',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.disableInputs,"id":'street_name_' + index},model:{value:(item.street),callback:function ($$v) {_vm.$set(item, "street", $$v)},expression:"item.street"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('street_nr')}},[_c('validation-provider',{attrs:{"name":'street_nr',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.disableInputs,"type":"number","id":'street_nr_' + index},model:{value:(item.street_nr),callback:function ($$v) {_vm.$set(item, "street_nr", $$v)},expression:"item.street_nr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('zip')}},[_c('validation-provider',{attrs:{"name":'zip_code',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.disableInputs,"type":"number","id":'zip_code_' + index},model:{value:(item.zip),callback:function ($$v) {_vm.$set(item, "zip", $$v)},expression:"item.zip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"8"}},[_c('b-form-group',{attrs:{"label":_vm.$t('city')}},[_c('validation-provider',{attrs:{"name":'city',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.disableInputs,"id":'city_' + index},model:{value:(item.city),callback:function ($$v) {_vm.$set(item, "city", $$v)},expression:"item.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-form-group',{attrs:{"label":_vm.$t('country_name')}},[_c('validation-provider',{attrs:{"name":'country',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-select',{attrs:{"disabled":_vm.disableInputs,"options":_vm.countries,"id":'country_' + index},model:{value:(item.country),callback:function ($$v) {_vm.$set(item, "country", $$v)},expression:"item.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)])}),0):_vm._e()],1)],1),_c('b-row',{staticClass:"mt-5"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"id":"confirmation_checkbox"}},[_c('validation-provider',{attrs:{"name":"confirmation_checkbox","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"confirmation_checkbox","name":"confirmation_checkbox","value":"true","unchecked-value":"false","state":errors.length > 0 ? false : null,"required":""},model:{value:(_vm.confirmation_checkbox),callback:function ($$v) {_vm.confirmation_checkbox=$$v},expression:"confirmation_checkbox"}},[_vm._v(" "+_vm._s(_vm.$t("confirmation_beneficial_owner_checkbox_text"))+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }